import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import Feedback from '../components/Feedback'
import HubspotForm from "react-hubspot-form"
import ProductVideoComp from '../components/ProductVideoComp'
import V3Features from '../components/V3Features'


const V3 = ({ location }) => {

    const [vidWidth, setvidWidth] = useState('')

  return (
    <Layout location={location}>

        <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-3 overflow-y-hidden z-0 px-5 xl:px-6" id='hero'>
            <div class="xl:w-11/12 2xl:max-w-7xl grid xl:grid-cols-2 justify-center items-start z-10 relative">
                <div class="w-full mx-auto flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
                    {/* <p class="my-5 text-base md:text-lg xl:text-xl text-purple-100 text-center xl:text-left w-full md:w-9/12 lg:w-full">An Employee Rewards & Recognition Platform trusted by companies globally!</p> */}
                    <h1 id="hero-text" class="text-purple-100 lg:pr-3 text-3xl md:text-4xl xl:text-6xl text-center xl:text-left mb-3 xl:mb-0">Globally trusted Employee<span className="text-orange"> Rewards & Recognition</span> Platform!</h1>
                    <p class="my-5 text-base md:text-lg xl:text-xl text-purple-100 text-center xl:text-left w-full md:w-9/12 xl:w-full">Automate your employee recognition efforts with our award-winning, Rewards & Recognition Platform, loved by both HR professionals and employees worldwide.</p>
                    {/* <ul class="list-inside orangebullets lato text-base md:text-lg xl:text-xl md:text-md 2xl:text-lg xl:px-0 w-full md:w-9/12 lg:w-full text-left">
                        <li><p className='text-purple-100'>Employee <span class="text-orange">Wellness Platform</span></p></li>
                        <li><p className='text-purple-100'>Employee <span class="text-orange">Discounts Platform</span></p></li>
                        <li><p className='text-purple-100'>Employee <spam class="text-orange">Survey and Analysis Platform</spam></p></li>
                    </ul> */}
                    <div class="md:flex justify-center items-center lg:justify-start w-full md:w-10/12 xl:w-10/12 cursor-pointer my-5 z-10">
                        <a class="vc-orange-btn text-white lato rounded-full mb-3 w-full xl:mb-0 xl:mr-2 mx-auto lg:mx-0" href="#form">Book a demo</a>
                        <a class="vc-new-white-border-btn-rounded-full text-white lato rounded-full mb-3 mx-auto lg:mx-0 w-full xl:mb-0 xl:mr-2" href="#prodvideo">Watch video</a>
                    </div>
                </div>
                <div class="flex justify-center items-center h-full mt-6 xl:mt-0 lg:ml-3">
                    <picture>
                        <source srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/06/rnr-paid-hero.webp" media="(min-width: 640px)" width="400" height="536" />

                        <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_335/q_auto/gatsbycms/uploads/2023/06/rnr-paid-hero.webp" alt="A description of the image." width="335" height="449" />        
                    </picture>
                    {/* <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/rnr-paid-hero.webp" alt="Employees celebrating with excitement and joy as they receive rewards and recognition in a company" width="400" /> */}
                </div>
                {/* <p className='pt-6 xl:absolute bottom-5 text-purple-100 text-xs'><sup>*</sup>T&C apply</p> */}
            </div>
        </section>

        <section class="w-full flex flex-col place-items-center py-10 xl:py-16 px-2 xl:px-0" id= "features">
            <h2 class="lg:pr-3 w-full md:w-4/6 xl:max-w-5xl mx-auto homepage-heading text-center px-2"><span class="text-orange">All-in-one</span> suite</h2>
            <p class="text-gray-500 text-base md:text-lg xl:text-xl py-3 text-center px-3">Our SaaS products are powerful alone but stronger together</p>
            <div class="xl:grid justify-center items-center max-w-7xl mx-auto my-2 xl:px-0 rounded-xl overflow-hidden xl:overflow-visible bg-gray-100" style={{ gridTemplateColumns: '1fr 3fr' }}>
                <div className='px-3 md:px-4 xl:rounded-xl z-10 h-full py-5 xl:py-0' style={{ background: '#d5d6fc', boxShadow: '20px 0px 35px rgba(41,41,76, 0.145)' }}>
                    <h2 className='hidden xl:block text-3xl xl:text-3xl lato text-center py-5 opacity-0'>Fre</h2>

                    <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 bg-white md:w-11/12 xl:w-full mx-auto shadow min-h-950 h-auto">
                        
                        <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/w_248/gatsbycms/uploads/2023/06/Home-Rewards-Card.webp" alt="Hoempage-Rewards-1" width="300" height="231" />
                        <h3 class="lato py- text-center">Employee Rewards and Recognition platform</h3>
                        <ul class="flex flex-col w-full">
                            <li class="flex w-full mt-5 pt-3 pb-1 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Rewards & Recognition redemption</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Global Capabilities</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Analytics & Reporting</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Social Integrations</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">HRMS/HRIS Integrations</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">SSO Integration</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Branding</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Data & Security</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Utility</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Support</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">Consultation</p>
                                </div>
                            </li>
                            <li class="flex w-full py-3 ml-1">
                                <div class="w-1/12"><img
                                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                    class="mt-1"
                                    width="20"
                                    height="20"
                                /></div>
                                <div class="w-10/12">
                                <p class="pl-2 m-0 text-sm">AI capabilities</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='grid place-content-center px-3 md:px-5 py-5 xl:py-5 xl:rounded-r-xl z-0 bg-gray-100 relative'>
                    <p className='absolute right-5 top-3 xl:top-5 text-xs '>*T&C apply</p>
                    <h2 className='text-3xl xl:text-3xl lato text-center py-3'>*Complimentary lifetime access to</h2>
                    <div className='xl:grid grid-cols-3 gap-6 place-content-center'>

                        <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 mt-3 mb-5 xl:my-5 shadow bg-white min-h-950 h-auto">
                            
                            <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/w_250/gatsbycms/uploads/2023/06/Home-Vfit-Card.webp" alt="Hoempage-vfit-1" width="300" height="231" />
                            <h3 class="lato pb- text-center">Employee Wellness <br/> platform</h3>
                            <ul class="flex flex-col w-full">
                                <li class="flex w-full mt-5 pt-3 pb-1 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Streak, race and themed challenges</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Journey challenges</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Custom challenges</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Unified health challenges</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Rich wellness library for employees</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Offers: Physical, Mental & Financial well-being</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">HR admin dashboard</p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-5 xl:my-5 shadow bg-white min-h-950 h-auto">
                            
                            <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/w_250/gatsbycms/uploads/2023/06/Home-Pulse-Card.webp" alt="Hoempage-pulse-1" width="300" height="231" />
                            <h3 class="lato text-center">Employee Feedback <br/> platform</h3>
                            <ul class="flex flex-col w-full">
                                <li class="flex w-full mt-5 pt-3 pb-1 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Anonymous feedback</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Employee experience insights</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Lifecycle experience insights</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">eNPS survey</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Standard questions</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Unlimited questions</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Heatmap analysis</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Industry benchmarking</p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-5 xl:my-5 shadow bg-white min-h-950 h-auto">
                            
                            <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/w_250/gatsbycms/uploads/2023/06/Home-Perks-Card.webp" alt="Hoempage-perks-1" width="300" height="231" />
                            <h3 class="lato text-center">Corporate Discounts <br/> platform</h3>
                            <ul class="flex flex-col w-full">
                                <li class="flex w-full mt-5 pt-3 pb-1 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Cashback offers</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Classified</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Custom offers</p>
                                    </div>
                                </li>
                                <li class="flex w-full py-3 ml-1">
                                    <div class="w-1/12"><img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp"
                                        class="mt-1"
                                        width="20"
                                        height="20"
                                    /></div>
                                    <div class="w-10/12">
                                    <p class="pl-2 m-0 text-sm">Exclusive offers</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="w-full flex place-content-center overflow-hidden py-10 xl:py-20 px-6 lg:px-0" id="PulseVC">
                <div class="lg:flex lg:w-11/12 xl:max-w-7xl mx-auto bg-indigo-100 overflow-hidden rounded-xl px-0">
                    <div class="card-1 w-full md:mb-0 xl:mt-0 lg:w-full xl:w-2/3 2xl:w-2/3 overflow-hidden relative lg:flex">
                        <div className='lg:w-full grid place-content-center md:place-content-start'>    
                        <div className='flex place-content-center lg:justify-start w-5/6 p-7 md:pl-8 lg:pt-12 xl:pl-12 mx-auto lg:mx-0'>
                            <h2 className='homepage-heading text-purple-100 text-center lg:text-left'>
                            Making life easy for HRs
                            </h2>
                        </div>
                        {/* <h2 class="text-2xl md:text-4xl mx-auto md:mx-0 text-center md:text-left text-purple-100 pt-5 mb-2 xl:mb-2 lg:px-0 lg:w-10/12 xl:w-10/12 2xl:w-9/12">Get a <span className='text-orange'>free demo</span></h2> */}
                        {/* <p className='lg:w-11/12 mx-auto lg:mx-0 text-center md:text-left text-purple-100  p-7 pb-0 lg:pl-8 xl:p-7 xl:pl-12 m-0 pb-2'>See Vantage Perks in action</p> */}
                        <p className='text-purple-100 xl:my-0 px-5 md:pl-8 xl:p-2 xl:pb-2 xl:pl-12 w-full md:w-4/6 m-0'>Quick Glimpse into our Rewards & Recognition platform with our intuitive and easy-to-use dashboard.</p>
                        <ul className='flex flex-col w-full md:w-4/6 lg:w-5/6 xl:w-4/6 pl-5 md:pl-8 xl:p-3 xl:pl-12 my-6 lg:mb-6 lg:mt-0'>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Mobile & web application</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Product adoption program</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Easy budget management</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Dedicated client success team</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Panel & team awards</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>24*5 customer support</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 ml-1'>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-10/12'><p className='pl-2 m-0 text-sm  text-purple-100'>Available in 16+ languages</p></div>
                            </li>
                        </ul>
                        </div>
                        <div className='flex justify-center md:justify-end md:w-full lg:w-1/3 justify-end items-end lg:hidden xl:block md:absolute md:right-0 lg:right-5 -bottom-0'>
                            <picture>
                                <source className="mt-3 transform scale-100 xl:scale-150 origin-bottom" loading="" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2023/11/CTA-banner-IMG-1.webp" type="image/webp" alt="Capterra" width="280" />
                                <img loading="" className='mt-3 transform scale-100 xl:scale-150 origin-bottom' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/CTA-banner-IMG-1.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2023/10/CTA-banner-IMG-1.webp" alt="Capterra" width="280" />
                            </picture>
                        </div>
                    </div>
                    <div class="card-1 rnrForm w-full xl:mt-0 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center px-6 py-7 lg:py-10 bg-indigo-50 bookBtnFix" id="form">
                        <h2 class="text-2xl lato text-purple-100 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step away from fostering a dynamic culture of recognition</h2>
                        <div class="w-full flex justify-center items-center lato rewards  blackLabelForm team">
                            <div class="flex justify-center items-center">
                                <HubspotForm
                                portalId="6153052"
                                formId="d20b7409-6dcc-4bdc-ad67-7321eb5b4ddb"
                                onSubmit={() => console.log("Submit!")}
                                onReady={form => console.log("Form ready!")}
                                loading={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        <div id = "v3Features"></div>

        <section className='rewards-bg-1 feedback py-10 xl:py-20 relative'>
             <div className='mx-auto lg:mx-w-5xl px-3' id='feedbackCarousel'></div>
        </section>

        <section class="w-full flex flex-col justify-center items-center px-5 py-10">
            <div class="max-w-7xl">
                <h2 class="text-gray-250 homepage-heading xl:max-w-4xl mx-auto xl:mt-10 mb-5 text-center">Winner of <span class="text-orange">top global awards</span> in Employee Recognition and Engagement</h2>
                <div class="grid grid-cols-2 xl:grid-cols-3 py-5 gap-2 items-center justify-center">
                    <div class="flex justify-center my-5">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_130/v1628664737/gatsbycms/uploads/2023/10/G2-Leader.webp" alt="G2 award4" width="130" height="125" />
                    </div>
                    <div class="flex justify-center my-5">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_190/v1628664738/gatsbycms/uploads/2023/10/BD-Badge-Base-min.webp" alt="Baker's Dozen award" width="190" height="100" />
                    </div>
                    <div class="flex justify-center my-5">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_190/v1629548690/gatsbycms/uploads/2023/10/TM_Gold-min-scaled.webp" alt="Brandon hall award" width="190" height="110" />
                    </div>
                </div>
            </div>
        </section>

        <section className='bg-gray-100 w-full flex justify-center py-10 px-5' id='casestudies'>
            <div className='max-w-7xl xl:flex'>
                <div className=''>
                    <h2 className='homepage-heading text-center xl:text-left text-indigo-100 mb-8 md:px-12 xl:px-0 xl:w-9/12'>Vantage Rewards <span className='text-orange'>helped 700+</span> global organizations</h2>
                    <p className='text-indigo-100 xl:w-8/12 text-lg xl:text-xl text-center xl:text-left'>See how organizations are using Vantage Circle to build an engaged and productive workplace</p>
                    <div class="flex justify-center items-center lg:justify-start w-5/6 mx-auto xl:mx-0 md:w-1/2 xl:w-4/12 cursor-pointer py-5">
                        <a class="vc-new-orange-btn-rounded-full lato rounded-full w-full" href="#form">Get started</a>
                    </div>
                </div>
                <div className='w-full md:w-10/12 mx-auto xl:w-7/12 my-5'>
                    <p><div className='bg-white rounded flex px-7 py-5 w-full my-5 border border-gray-150'>
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/w_80/gatsbycms/uploads/2023/06/rnr-cx-logos-wipro-1.webp" alt="Wipro Logo" width= "80" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>Wipro experienced an increase in appreciation by 62.53% in 2022-23</p>
                    </div></p>
                    <p><div className='bg-white rounded flex px-7 py-5 w-full my-5 border border-gray-150'>
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/w_80/gatsbycms/uploads/2023/06/rnr-cx-logos-tatamotors.webp" alt="Tata Motors Logo" width= "80" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>TATA Motors increased peer-to-peer recognition by 33.47% in 2022-23</p>
                    </div></p>
                    <p><div className='bg-white rounded flex px-7 py-5 w-full my-5 border border-gray-150'>
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/w_80/gatsbycms/uploads/2023/06/rnr-cx-logos-infosys.webp" alt="Infosys Logo" width= "80" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>Infosys improved its employee engagement by 15%</p>
                    </div></p>
                </div>
            </div>
        </section> 

        <section className='w-full bg-indigo-100 px-3 flex flex-col items-center justify-center py-6'>
            <div className='xl:grid grid-cols-2 gap-10 justify-center items-center max-w-7xl mt-5 xl:mt-20 2xl:px-3'>
                <div className='px-3 2xl:pl-5'>
                    <div className='flex justify-start w-full'><h2 className='text-3xl text-left md:text-center w-full xl:text-left md:text-4xl pb-8 xl:pb-3 text-purple-100'>Product Video</h2></div>
                    <div id="prodVidComp" data-youtube-url = {`https://www.youtube.com/embed/_91XkfNchEA`} data-alt = {`Vantage Rewards – Employee Rewards and Digital Gifting Platform | Employee Benefits`}></div>
                </div>
                <div className='px-3 xl:border-l border-white xl:pl-10 flex flex-col justify-start pt-10 xl:pt-0'>
                    <h2 className='text-3xl text-left md:text-center xl:text-left md:text-4xl text-purple-100 xl:pt-0 pt-5 xl:pb-5 transform -translate-y-2'>Grow with us</h2>
                    <div className='flex flex-col justify-start' style = {{height: '300px'}}>
                        <p className='text-purple-100 text-lg lg:text-xl text-left md:text-center xl:text-left lg:w-4/6 xl:w-full mx-auto xl:mx-0 pt-2 xl:pt-0'>Join our community and deliver a satisfying employee experience with our global employee engagement and wellness platform.</p>
                        <div className='flex pt-10 w-full'>
                            <div className='w-5/12 xl:w-5/12 pr-2 xl:px-0 flex flex-col items-center xl:items-start'>
                                <h2 className='text-4xl xl:text-6xl text-orange'>10K+</h2>
                                <p className='text-purple-100'>Options</p>
                            </div>
                            <div className='w-1/2 xl:w-1/2 pl-3 xl:pl-10 border-l border-purple-100 flex flex-col items-center xl:items-start'>
                                <h2 className='text-4xl xl:text-6xl text-orange'>700+</h2>
                                <p className='text-purple-100'>Clients</p>
                            </div>
                            <div className='w-1/2 xl:w-1/2 pl-3 xl:pl-10 border-l border-purple-100 flex flex-col items-center xl:items-start'>
                                <h2 className='text-4xl xl:text-6xl text-orange'>3.2M+</h2>
                                <p className='text-purple-100'>Users</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </Layout>
  )
}

export default V3